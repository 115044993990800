import React from "react"
import __c from "../utilities/combineClasses"
import Layout from "../components/layout"
import SEO from "../components/seo"

import style from "./demo.module.scss"

export default () => {
  return (
    <Layout className={style.gridLayout}>
      <SEO title="See VariaMetrix in action" />
      <article className={__c(style.article)}>
        <header className={__c(style.header)}>
          <h1 className={__c(style.pageTitle)}>Schedule your demo</h1>
        </header>
        <main className={__c(style.main)}>
          <div className={__c(style.introduction)}>
            <p>
              You can view a prerecorded demonstration right here. You can also
              request a personalized demonstration that addresses the specific
              marketing challenges of your business.
            </p>
          </div>
          <div className={__c(style.cta)}>
            <a className={__c(`aside-button`)} href="https://cal.services/don/Chat-with-Don/0rUMt6jJ2">Schedule a demo</a>
          </div>
          <div className={__c(style.video)}>
            <div className={__c(style.videoContainer)}>
              <div className={__c(style.videoElement)}>[VIDEO]</div>
            </div>
          </div>
        </main>
      </article>
    </Layout>
  )
}
